import * as Html from 'BaxterScript/helper/browser/Html';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { Config } from 'BaxterScript/types/Config';
import { baxterV2Enabled } from 'BaxterScript/version/web/BaxterV2Enabled';

export const webpackExclude = (config: Config): boolean => baxterV2Enabled(config);

export function videoJSStyleDependencies(cdnDomain, version) {
  return [
    {
      id: 'videojsima',
      url: `${cdnDomain}/_assets/videojsima/${version}/videojsima-with-deps.css`,
    },
  ];
}

export function videoJSScriptDependencies(cdnDomain, version) {
  return [
    {
      id: 'ima3',
      url: 'https://imasdk.googleapis.com/js/sdkloader/ima3.js',
    },
    {
      id: 'videojsima',
      url: `${cdnDomain}/_assets/videojsima/${version}/videojsima-with-deps.min.js`,
    },
  ];
}

export function videoJSCreateTags(divId, innerVideoId, posterUrl, sources, cdnDomain) {
  const div = Html.getElementById(divId);
  if (div) {
    const sourcesTags =
      sources && sources.length
        ? sources.map((source) => `<source src="${source.src}" type="${source.type}" />`).join('\n')
        : `<source src="${cdnDomain}/dummynonexistingvideo.mp4" type="video/mp4" />`;
    div.innerHTML = `<video-js id="${innerVideoId}" controls preload="none"
                        class="video-js vjs-default-skin vjs-big-play-centered vjs-fluid baxter-inner-video" poster="${posterUrl}">
                        ${sourcesTags}
        </video-js>`;
  }
}

export function videoJSPlay(player) {
  const playResult = player.play();
  if (playResult !== undefined) {
    playResult.catch(() => {
      console.debug('[SLOTS][VIDEOJS] ON PLAY: AUTOPLAY WAS PREVENTED');
      newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_AUTOPLAY_WAS_PREVENTED);
    });
  } else {
    console.debug('[SLOTS][VIDEOJS] ON PLAY: AUTOPLAY RESULT NOT DEFINED');
    newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_AUTOPLAY_RESULT_UNDEFINED);
  }
}

export function videoJSPlayer(innerVideoId, options: Record<string, unknown> | null = null) {
  return options != null ? globalThis.videojs(innerVideoId, options) : globalThis.videojs(innerVideoId);
}

export function videoJSDefaultOptions(id, adLabel) {
  return {
    id,
    adLabel,
    vastLoadTimeout: 20000,
    adsRenderingSettings: {
      loadVideoTimeout: 20000,
    },
    contribAdsSettings: {
      timeout: 20000,
      prerollTimeout: 20000,
    },
    numRedirects: 10,
  } as Record<string, unknown>;
}

export function videoJSRemoveControls(innerVideoId) {
  // Remove controls from the player on iPad or Android to stop native controls from stealing
  // our click
  const contentPlayer = document.getElementById(`${innerVideoId}_html5_api`);
  if (
    (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/Android/i)) &&
    contentPlayer?.hasAttribute('controls')
  ) {
    contentPlayer?.removeAttribute('controls');
  }
}

export function videoJSInitializeAtFirstClick(innerVideoId, player) {
  // Initialize the ad container when the video player is clicked, but only the
  // first time it's clicked.
  const initAdDisplayContainer = function () {
    try {
      player.ima.initializeAdDisplayContainer();
      wrapperDiv?.removeEventListener(startEvent, initAdDisplayContainer);
    } catch (e) {
      console.error('[SLOTS][VIDEOJS][INITADDISPLAYCONTAINER]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEIMA_INIT_AD_DISPLAY_CONTAINER, { message: (e as Error).message });
      throw e;
    }
  };
  let startEvent = 'click';
  if (
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/Android/i)
  ) {
    startEvent = 'touchend';
  }
  let wrapperDiv = Html.getElementById(innerVideoId);
  if (wrapperDiv) {
    wrapperDiv.addEventListener(startEvent, initAdDisplayContainer);
  }
}
