import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';

interface StaticAdConfig {
  dsaLabelCss: string;
  dsaIconColor: string;
  dsaIconBackgroundColor: string;
  label: string;
  staticCreative: HTMLDivElement;
  modalTitle: string;
  advertiserLabel: string;
  purchaserLabel: string;
  reasonLabel: string;
  reasonValue: string;
  closeButtonLabel: string;
  pageId: string;
  containerId: string;
  slotId: string;
}

export const DSA_STYLES = `
    .baxter-dsa {
      position: fixed;
      z-index: 9999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      overflow: auto;
    }
    .baxter-dsa-modal {
      font-family: "Intel", Helvetica, sans-serif;
      background-color: white;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 288px;
      width: 90%;
      max-width: 464px;
      color: #02282C;
      border-radius: 4px;
      word-break: break-word;
    }
    .baxter-dsa-modal-header {
      display: flex;
      padding: 24px;
      justify-content: flex-end;
      align-items: center;
      align-self: stretch;
    }
    .baxter-dsa-modal-body {
      margin: 0px 24px;
      font-size: 18px;
      line-height: 24px;
    }
    .baxter-dsa-modal-body__title {
      margin: 0px;
      text-align: center;
      font-size: 20px;
      line-height: 22px;
    }
    .baxter-dsa-modal-body__list-title {
      margin-top: 16px;
    }
    .baxter-dsa-modal-body ul {
      margin-top: 6px;
      margin-left: 24px;
    }
    .baxter-dsa-modal-footer {
      margin: 24px;
      display: flex;
      justify-content: center;
    }
    .baxter-dsa-modal-footer__button {
      font-family: "Intel", Helvetica, sans-serif;
      display: flex;
      padding: 14px 32px;
      font-size: 16px;
      border: 1px solid #1976D2;
      border-radius: 4px;
      color: white;
      background: #1976D2;
      cursor: pointer;
    }
    .baxter-dsa-modal-overflow-hidden {
      overflow: hidden;
    }
`;

class DsaTemplate {
  private config: StaticAdConfig;

  private templateContainer: HTMLDivElement;

  private modal: HTMLDivElement | null;

  constructor(config: StaticAdConfig) {
    this.config = config;
    this.templateContainer = this.initContainer();
    this.modal = null;
  }

  public get container() {
    return this.templateContainer;
  }

  private initContainer() {
    console.info('[SLOTS][DSATEMPLATE][INITCONTAINER]', JSON.stringify(this.config), this.config.staticCreative);
    const container = document.createElement('div');
    container.style.position = 'relative';
    const button = document.createElement('button');
    button.style.cssText = `${this.config.dsaLabelCss}`;
    button.innerHTML = `
      ${this.config.label ? `<span>${this.config.label}</span>` : ''}
      <svg width="24" height="24" viewBox="-4 -4 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" rx="8" fill="${this.config.dsaIconBackgroundColor}" fill-opacity="0.8"/>
        <path d="M8.6 10.4V7.4L8 6.8L7.4 7.4V10.4L8 11L8.6 10.4Z" fill="${this.config.dsaIconColor}"/>
        <path d="M8 5C8.3312 5 8.6 5.2688 8.6 5.6C8.6 5.9312 8.3312 6.2 8 6.2C7.6688 6.2 7.4 5.9312 7.4 5.6C7.4 5.2688 7.6688 5 8 5Z" fill="${this.config.dsaIconColor}"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 8C2 11.3084 4.6916 14 8 14C11.3084 14 14 11.3084 14 8C14 4.6916 11.3084 2 8 2C4.6916 2 2 4.6916 2 8ZM3.2 8C3.2 5.3534 5.3534 3.2 8 3.2C10.6466 3.2 12.8 5.3534 12.8 8C12.8 10.6466 10.6466 12.8 8 12.8C5.3534 12.8 3.2 10.6466 3.2 8Z" fill="${this.config.dsaIconColor}"/>
      </svg>`;
    button.addEventListener('click', () => this.createModal());
    container.appendChild(button);
    container.appendChild(this.config.staticCreative);
    return container;
  }

  private createModal() {
    console.info('[SLOTS][DSATEMPLATE][CREATEMODAL]');
    try {
      this.modal = document.createElement('div');
      this.modal.setAttribute('class', 'baxter-dsa');
      this.modal.innerHTML = this.getModalContent();
      this.modal.querySelector('svg')?.addEventListener('click', () => this.removeModal());
      this.modal.querySelector('.baxter-dsa-modal-footer__button')?.addEventListener('click', () => this.removeModal());
      document.body.appendChild(this.modal);
      document.body.classList.add('baxter-dsa-modal-overflow-hidden');
      document.onkeydown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          this.removeModal();
        }
      };
      newRelicMetrics.reportMetric(NewRelicMetric.ADMANAGER_STATIC_DSA_MODAL);
    } catch (e) {
      console.error('[SLOTS][DSATEMPLATE][CREATEMODAL]', e);
      newRelicMetrics.reportError(NewRelicError.ADMANAGER_STATIC_DSA_MODAL_OPEN_ERROR, {
        message: (e as Error).message,
      });
      throw e;
    }
  }

  private getModalContent() {
    console.info('[SLOTS][DSATEMPLATE][GETMODALCONTENT]');
    const element = this.templateContainer?.getElementsByTagName('button')?.[0];
    let advertiser = 'unknown';
    let purchaser = 'unknown';
    const dsaContainerElement = element.parentElement?.querySelector(
      '[data-advertiser][data-purchaser]'
    ) as HTMLElement;
    if (dsaContainerElement) {
      advertiser = dsaContainerElement.dataset.advertiser!;
      purchaser = dsaContainerElement.dataset.purchaser!;
    }

    return `<div class="baxter-dsa-modal">
      <div class="baxter-dsa-modal-header">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" style="cursor: pointer;">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M21.086 2L12.5 10.5855L3.914 2H2.5V3.414L11.086 12L2.5 20.586L2.5 22H3.914L12.5 13.414L21.086 22H22.5V20.586L13.9145 12L22.5 3.414V2L21.086 2Z" fill="#02282C"/>
        </svg>
      </div>
      <div class="baxter-dsa-modal-body">
        <h4 class="baxter-dsa-modal-body__title">${this.config.modalTitle}</h4>
        <div class="baxter-dsa-modal-body__list-title">${this.config.advertiserLabel}</div>
        <ul><li id="advertiser-value">${advertiser}</li></ul>
        <div class="baxter-dsa-modal-body__list-title">${this.config.purchaserLabel}</div>
        <ul><li id="purchaser-value">${purchaser}</li></ul>
        <div class="baxter-dsa-modal-body__list-title">${this.config.reasonLabel}</div>
        <ul><li>${this.config.reasonValue}</li></ul>
      </div>
      <div class="baxter-dsa-modal-footer">
        <button class="baxter-dsa-modal-footer__button">${this.config.closeButtonLabel}</button>
      </div>
    </div>`;
  }

  private removeModal() {
    console.info('[SLOTS][DSATEMPLATE][REMOVEMODAL]', this.modal);
    try {
      this.modal?.remove();
      document.onkeydown = null;
      document.body.classList.remove('baxter-dsa-modal-overflow-hidden');
    } catch (e) {
      console.error('[SLOTS][DSATEMPLATE][REMOVEMODAL]', e);
      newRelicMetrics.reportError(NewRelicError.ADMANAGER_STATIC_DSA_MODAL_CLOSE_ERROR, {
        message: (e as Error).message,
      });
      throw e;
    }
  }
}

export const getDsaCompliantStaticAd = (staticAdConfig: StaticAdConfig) => new DsaTemplate(staticAdConfig).container;
