export const get = (key) => {
  const name = `${key}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const splittedCookie = decodedCookie.split(';');
  for (let i = 0; i < splittedCookie.length; i++) {
    let c = splittedCookie[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const set = (key, val, expiry) => {
  console.info('[SLOTS][COOKIE][SET]', key, val, expiry);
  const cookie = `${key}=${val}; expires=${new Date(expiry).toUTCString()}; path=/`;
  console.debug('[SLOTS][COOKIE][SET] document.cookie =', cookie);
  document.cookie = cookie;
};

export const remove = (name) => {
  console.info('[SLOTS][COOKIE][REMOVE]', name);
  const cookie = `${name}=; expires=${new Date(0).toUTCString()}; path=/`;
  console.debug('[SLOTS][COOKIE][REMOVE] document.cookie =', cookie);
  document.cookie = cookie;
};
