export enum NinjaMetric {
  INTERSTITIAL_ADREQUEST_SENT = 'interstitial_adrequest_sent',
  INTERSTITIAL_ADREQUEST_RECEIVED = 'interstitial_adrequest_received',
  INTERSTITIAL_RENDERED = 'interstitial_rendered',
  INTERSTITIAL_IMPRESSION = 'interstitial_impression',
  INTERSTITIAL_PRELOADED = 'interstitial_preloaded',
  INTERSTITIAL_VIEWABLE = 'interstitial_viewable',
  ADVERTISEMENT_DISPLAYED = 'advertisement_displayed',
  ADVERTISEMENT_VIEWED = 'advertisement_viewed',
  STICKY_AD_DISPLAYED = 'sticky_ad_displayed',
  STICKY_AD_CLOSED = 'sticky_ad_closed',
}
