import { TargetingParams } from 'BaxterScript/types/TargetingParams';

/**
 * @deprecated
 * Extend Slot, Lifecycle, etc. entities instead or create a new one.
 * Use globalThis.Baxter.state as storage.
 */
export class ContainerParamsRepository {
  private slotContainer: Record<string, TargetingParams>;

  constructor() {
    this.slotContainer = {};
  }

  save(pageId: string, containerId: string, params: TargetingParams) {
    this.slotContainer[`${pageId}#${containerId}`] = params;
  }

  findBy(pageId: string, containerId: string) {
    return this.slotContainer[`${pageId}#${containerId}`] || {};
  }
}
