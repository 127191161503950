export class NewRelicMetrics {
  constructor(
    private waitForLoadTime: number,
    private sample: number
  ) {}

  sendMetric(name, parameters, skipSampling = false) {
    Object.keys(parameters).forEach((p) => {
      if (typeof parameters[p] === 'object') parameters[p] = JSON.stringify(parameters[p]);
    });
    if (!globalThis.NREUM) {
      setTimeout(() => {
        try {
          this.doSendIfSampling(skipSampling, name, parameters);
        } catch (e) {
          console.error(`[SLOTS][NEWRELICMETRICS][SENDMETRIC] ${name}`, parameters, e);
        }
      }, this.waitForLoadTime);
    } else {
      this.doSendIfSampling(skipSampling, name, parameters);
    }
  }

  doSendIfSampling(skipSampling, name, parameters) {
    // NOSONAR
    const random = Math.random();
    const randomNum = Math.floor(random * 100);
    if (skipSampling || randomNum < this.sample) {
      this.doSend(name, parameters);
    } else {
      console.debug(`[SLOTS][NEWRELICMETRICS][SENDMETRIC] skip because of sampling ${name}`, parameters);
    }
  }

  doSend(name, parameters) {
    console.debug(
      `[SLOTS][NEWRELICMETRICS][DOSEND] ${globalThis.NREUM ? 'SENDING ' : 'NO NEWRELIC FOR '} ${name}`,
      parameters
    );
    if (globalThis.NREUM) {
      globalThis.NREUM.addPageAction(name, parameters);
    }
  }
}
