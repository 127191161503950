export const creativeSources = [
  'https://cdn.selfserve.baxter.olx.org/',
  'https://cdn.selfserve.dev.baxter.olx.org/',
  'https://cdn.selfserve.staging.baxter.olx.org/',
  'https://cdn.slots.baxter.olx.org/',
  'https://cdn.slots.dev.baxter.olx.org/',
  'https://cdn.slots.staging.baxter.olx.org/',
  'https://cdn.admanager.baxter.olx.org',
  'https://cdn.admanager.dev.baxter.olx.org',
  'https://cdn.admanager.staging.baxter.olx.org',
];
