import * as State from 'BaxterScript/version/web/core/State';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { Slot } from 'BaxterScript/types/Slot';
import { GoogleAdsConfig } from 'BaxterScript/types/ProviderSettings/GoogleAds';
import { GoogleImaConfig } from 'BaxterScript/types/ProviderSettings/GoogleIma';
import { baxterV2Enabled } from 'BaxterScript/version/web/BaxterV2Enabled';

const GOOGLEADSID = Providers.GOOGLE_ADS;
const GOOGLEIMAID = Providers.GOOGLE_IMA;

const excludeForProvider = (config, id) => {
  const providerSettings = (config.slots?.providerSettings?.[id] ?? {}) as GoogleAdsConfig | GoogleImaConfig;
  const prebidSettings = providerSettings.prebid;
  return !(
    (prebidSettings?._ && Object.values(prebidSettings._).some((item) => item?.enabled === true)) ||
    (prebidSettings && Object.values(prebidSettings).some((item) => item?.enabled === true))
  );
};

export const webpackExclude = (config) =>
  (excludeForProvider(config, GOOGLEADSID) && excludeForProvider(config, GOOGLEIMAID)) || baxterV2Enabled(config);

export const init = () => {
  console.info('[SLOTS][PREBID][INIT]');
  globalThis.pbjs = globalThis.pbjs || {};
  globalThis.pbjs.que = globalThis.pbjs.que || [];
};

export const dependencies = () => {
  console.info('[SLOTS][PREBID][DEPENDENCIES]');
  const version = globalThis.Baxter.config.providers?.google?.prebid?.version;
  if (version) {
    return [
      {
        id: 'prebid',
        url: `${globalThis.Baxter.config.cdnDomain}/_assets/prebid/${version}.js`,
      },
    ];
  }
  return [];
};

export const mapCurrencySettings = (currencySettings: Record<string, unknown> = {}) => {
  console.info('[SLOTS][PREBID][MAPCURRENCYSETTINGS]', currencySettings);
  const mappedCurrencySettings = currencySettings || {};
  if (Array.isArray(currencySettings.bidderCurrencyDefault)) {
    mappedCurrencySettings.bidderCurrencyDefault = Object.fromEntries(
      currencySettings.bidderCurrencyDefault.map((item) => [item.bidder, item.currency])
    );
  }
  if (Array.isArray(currencySettings.defaultRates)) {
    mappedCurrencySettings.defaultRates = Object.fromEntries(
      currencySettings.defaultRates.map((item) => [
        item.name,
        Object.fromEntries((item.rates || []).map((subItem) => [subItem.currency, subItem.rate])),
      ])
    );
  }
  return mappedCurrencySettings;
};

export function createPrebidConfig(prebidSettings) {
  console.info('[SLOTS][PREBID][CREATEPREBIDCONFIG]', prebidSettings);
  const modules = prebidSettings.settings?.modules || [];
  if (globalThis.prebidCurrencyConfig) {
    newRelicMetrics.reportMetric(NewRelicMetric.PREBID_CURRENCY_CONFIG);
  }
  const currency = globalThis.prebidCurrencyConfig || mapCurrencySettings(prebidSettings.currency);
  const buckets = prebidSettings.settings?.buckets;
  const timeout = prebidSettings.settings?.timeout;
  const prebidConfig = {
    currency,
    bidderTimeout: timeout,
    enableSendAllBids: true,
    priceGranularity: { buckets },
    userSync: {
      iframeEnabled: true,
      filterSettings: {
        iframe: {
          bidders: '*',
          filter: 'include',
        },
      },
    },
    usePrebidCache: true,
    cache: {
      url: 'https://prebid.adnxs.com/pbc/v1/cache',
    },
  } as Record<string, unknown>;

  if (modules.includes('consentManagement')) {
    prebidConfig.consentManagement = {
      cpmApi: 'iab',
      timeout: typeof globalThis.gdprAppliesGlobally === 'undefined' ? 500 : 1000,
      allowAuctionWithoutConsent: true,
    };
  }
  return prebidConfig;
}

let alreadyLoaded = false;

export function createBidderSettings(prebidSettings, pbjs) {
  console.info('[SLOTS][PREBID][CREATEBIDDERSETTINGS]', prebidSettings);
  const bidderSettings = {} as Record<
    string,
    { bidCpmAdjustment: (bidCpm: number) => number; storageAllowed: boolean }
  >;
  const { bidders } = prebidSettings;
  Object.keys(bidders).forEach((bidder) => {
    const bidderConfig = bidders[bidder] || {};
    if (bidderConfig.alias) {
      pbjs.aliasBidder(bidderConfig.alias, bidder);
    }
    bidderSettings[bidder] = {
      bidCpmAdjustment: (bidCpm) => {
        const bid = bidCpm * bidderConfig.bidRate;
        return bid <= bidderConfig.bidFloor ? 0 : bid;
      },
      storageAllowed: true,
    };
  });
  return bidderSettings;
}

export const loaded = () => {
  console.info('[SLOTS][PREBID][LOADED]');
  if (alreadyLoaded) {
    return;
  }
  alreadyLoaded = true;
  const providerSettings = globalThis.Baxter.config.providers[GOOGLEADSID] || {};
  const prebidSettings = providerSettings.prebid || {};
  const { pbjs } = window;
  if (pbjs) {
    pbjs.que.push(() => {
      try {
        const prebidConfig = createPrebidConfig(prebidSettings);
        pbjs.setConfig(prebidConfig);
        pbjs.bidderSettings = createBidderSettings(prebidSettings, pbjs);
      } catch (e) {
        console.error('[SLOTS][PREBID][LOADED]', e);
        newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
          command: '[PREBIDLOADED]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
  } else {
    console.error(`[SLOTS][PREBID][LOADED]`);
    newRelicMetrics.reportError(NewRelicError.PREBID_NO_PBJS, { command: '[PREBIDLOADED]' });
  }
};

export const setTargeting = ({ dfp_user_id: dfpUserId, user_id: userId, user_email_sha256: sha256Hash }) => {
  console.info('[SLOTS][PREBID][SETTARGETING]');
  pbjs.que.push(() => {
    try {
      const config = {
        ortb2: { user: { ext: { eids: [{ source: window.location.hostname, uids: [] as unknown[] }] } } },
      };
      const ownUsersUids = config.ortb2.user.ext.eids[0].uids;
      if (dfpUserId) {
        ownUsersUids.push({ id: dfpUserId, atype: 1, ext: { stype: 'ppuid', persistence: 'http' } });
      }
      if (userId) {
        ownUsersUids.push({ id: userId, atype: 3, ext: { stype: 'ppuid', persistence: 'js' } });
      }
      if (sha256Hash) {
        ownUsersUids.push({ id: sha256Hash, atype: 3, ext: { stype: 'hemsha256' } });
      }
      config.ortb2.user.ext.eids = config.ortb2.user.ext.eids.filter((eid) => eid && eid.uids.length);
      const providerSettings = globalThis.Baxter.config.providers[GOOGLEADSID] || {};
      const prebidSettings = providerSettings.prebid || {};
      if (ownUsersUids.length && prebidSettings.bidders) {
        window.pbjs.setBidderConfig({ bidders: Object.keys(prebidSettings.bidders), config }, true);
      }
    } catch (e) {
      console.error('[SLOTS][PREBID][SETTARGETING]', e);
      newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
        command: '[PREBIDSETTARGETING]',
        message: (e as Error).message,
      });
      throw e;
    }
  });
};

export const transform = async (pageId, containerId, slotId, params, providerId): Promise<Slot> => {
  console.info('[SLOTS][PREBID][TRANSFORM]', pageId, containerId, slotId, params, providerId);
  const slot = {
    prebidSizes: [],
    prebidBidders: {},
  };
  const providerSettings = globalThis.Baxter.config.slots?.providerSettings?.[providerId];
  const prebidSettings =
    globalThis.Baxter.context.configurationService.getById(
      providerSettings?.prebid || {},
      pageId,
      containerId,
      slotId
    ) || {};
  if (prebidSettings.sizes) {
    const sizes = prebidSettings.sizes || [];
    slot.prebidSizes = sizes
      .map((size) => (size.split('x').length === 2 ? size.split('x').map(Number) : null))
      .filter(Boolean);
  }
  if (prebidSettings.bidders) {
    slot.prebidBidders = prebidSettings.bidders || {};
  }
  // @ts-ignore
  return slot;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const remove = (slots: Slot[] = [], providerId) => {
  console.info('[SLOTS][PREBID][REMOVE]', slots, providerId);
  const providerSettings = globalThis.Baxter.config.slots?.providerSettings?.[providerId];
  const prebidSettings = providerSettings?.prebid || {};
  if (globalThis.pbjs) {
    slots.forEach((slot) => {
      const prebidSlot =
        globalThis.Baxter.context.configurationService.getById(
          prebidSettings,
          State.getPageId(),
          slot.containerId,
          slot.id
        ) || {};
      if (prebidSlot.enabled) {
        globalThis.pbjs.que.push(() => {
          try {
            globalThis.pbjs.removeAdUnit(slot.path);
          } catch (e) {
            console.error('[SLOTS][PREBID][REMOVE]', e);
            newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
              command: '[PREBIDREMOVE]',
              message: (e as Error).message,
            });
            throw e;
          }
        });
      }
    });
  } else {
    console.error(`[SLOTS][PREBID][REMOVE]`);
    newRelicMetrics.reportError(NewRelicError.PREBID_NO_PBJS, { command: '[PREBIDREMOVE]' });
  }
};

const getParams = (bidderSetting) => {
  const ignoreKeys = ['targeting', 'biddersFloorPriceFields'];
  const params = {};
  Object.keys(bidderSetting).forEach((key) => {
    if (!ignoreKeys.includes(key)) params[key] = bidderSetting[key];
  });

  return params;
};
export const getBidParameter = (bidder, slotBidderConfig, slot) => {
  if (slotBidderConfig.targeting && slotBidderConfig.targeting.strategy === 'copy') {
    slotBidderConfig[slotBidderConfig.targeting.field] = slot.targeting;
  }
  const params = getParams(slotBidderConfig);
  return { bidder, params };
};
/**
 * Returns bid information for the specific slot. This will be used to create the external object sent to
 * pbjs.addAdUnits method.
 * @param slot
 * @returns {{}}
 */
export const getBids = (slot) => {
  console.info('[SLOTS][PREBID][GETBIDS]', slot);
  const providerSettings = globalThis.Baxter.config.providers[GOOGLEADSID] || {};
  const prebidSettings = providerSettings.prebid || { bidders: {} };
  const slotBidders = slot.prebidBidders || {};
  /**
   * slotBidders: the bidders part of the particular slot setting for prebids.
   * This is the point where partners should be filtered out if they are not DSA compliant.
   *  bidders: {
   *      bidder1: {
   *          bidRate: 0.9,
   *          bidFloor: 0.5,
   *          targeting: {
   *              strategy: '',
   *              field: 'location',
   *          },
   *          biddersFloorPriceFields: [],
   *      },
   *  }
   *  Sample:
   *  Object.keys(slotBidders).filter(bidder => bidder !== 'appnexus').forEach(bidder => {
   */
  const bidsByCodeType: Record<string, unknown[]> = {};
  Object.keys(slotBidders).forEach((bidder) => {
    const slotBidder = slotBidders[bidder];
    const bidderSettings = prebidSettings.bidders[bidder];
    /**
     * Example of bidder general setting (in Baxter.config)
     *  bidder1: {
     *     codeType: 'codebidder',
     *     alias: 'alias1',
     *     bidRate: 0.9,
     *     bidFloor: 0.5,
     * }
     */
    if (bidderSettings && typeof bidderSettings === 'object') {
      const bidderCodeType = bidderSettings.codeType || 'path';
      (Array.isArray(slotBidder) ? slotBidder : [slotBidder]).forEach((slotBidderConfig) => {
        const bids = bidsByCodeType[bidderCodeType];
        if (!bids) bidsByCodeType[bidderCodeType] = [];
        bidsByCodeType[bidderCodeType].push(getBidParameter(bidder, slotBidderConfig, slot));
      });
    }
  });

  return bidsByCodeType;
};

export default {
  dependencies,
  init,
  loaded,
  transform,
  getBids,
  remove,
  setTargeting,
};
