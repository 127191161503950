import * as State from 'BaxterScript/version/web/core/State';
import { NewRelicMetrics } from 'BaxterScript/helper/metrics/NewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';

export class BaxterNewRelicMetrics {
  constructor(
    private newRelicMetrics: NewRelicMetrics,
    private metricsOrErrorsNoSampling = {} as Record<NewRelicError | NewRelicMetric, boolean>,
    private accountNoSampling: Record<string, boolean> = {
      otodompl: true,
      imovirtualpt: true,
      storiaro: true,
    }
  ) {}

  shouldSkipSampling(name: NewRelicError | NewRelicMetric, accountId?: string) {
    return this.metricsOrErrorsNoSampling[name] || !!(accountId && this.accountNoSampling[accountId]);
  }

  sendMetric(name, parameters, skipSampling = false) {
    if (State && typeof State.getUserId === 'function') {
      parameters.userId = State.getUserId();
    }
    if (globalThis.Baxter?.state?.featureFlags) {
      parameters.featureFlag = globalThis.Baxter?.state?.featureFlags;
    }
    if (globalThis.Baxter?.state?.abTest) {
      parameters.abTest = globalThis.Baxter?.state?.abTest;
    }
    const baxterizedParameters = this.addBaxterPrefix(parameters);
    if (globalThis.Baxter && globalThis.Baxter.config) {
      const { accountId, versionId, appId, commitHash } = globalThis.Baxter.config;
      const baxterizedConfigInfo = this.addBaxterPrefix({ accountId, versionId, appId, commitHash });
      this.newRelicMetrics.sendMetric(name, { ...baxterizedConfigInfo, ...baxterizedParameters }, skipSampling);
    } else {
      this.newRelicMetrics.sendMetric(name, baxterizedParameters, skipSampling);
    }
  }

  reportMetric(name: NewRelicMetric, parameters = {}) {
    try {
      this.sendMetric(name, { ...parameters }, this.shouldSkipSampling(name, globalThis.Baxter?.config?.accountId));
    } catch (err) {
      console.error(err);
    }
  }

  reportError(errorCode: NewRelicError, parameters) {
    try {
      this.sendMetric(
        'BaxterError',
        { errorCode, ...parameters },
        this.shouldSkipSampling(errorCode, globalThis.Baxter?.config?.accountId)
      );
    } catch (err) {
      console.error(err);
    }
  }

  addBaxterPrefix(parameters) {
    const baxterized: Record<string, unknown> = {};
    for (const [key, value] of Object.entries(parameters)) {
      baxterized[`baxter${key.charAt(0).toUpperCase() + key.slice(1)}`] = value;
    }
    return baxterized;
  }
}

export default new BaxterNewRelicMetrics(new NewRelicMetrics(3000, 10));
