import ninjaMetrics from 'BaxterScript/helper/metrics/NinjaMetrics';
import * as Html from 'BaxterScript/helper/browser/Html';
import Placeholder from 'BaxterScript/version/web/feature/Placeholder';
import * as Interstitial from 'BaxterScript/version/web/provider/googleads/Interstitial';
import { buildSlotRenderedEvent } from 'BaxterScript/version/web/core/SlotRenderedEventListener';
import { id } from 'BaxterScript/version/web/provider/googleads/GoogleAds';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { NinjaMetric } from 'BaxterScript/helper/metrics/NinjaMetric';

export const impressionCallback = (external, containerId, slotId, pageId, path, slot) => (event) => {
  try {
    console.info('[SLOTS][GOOGLEADS][IMPRESSIONCALLBACK]');
    if (event.slot === external) {
      console.info(`[SLOTS][GOOGLEADS][IMPRESSIONCALLBACK][VIEWABILITY] ${path}`, event);
      slot.impression = true;
      slot.impressionScroll = window.scrollY;
      ninjaMetrics.reportMetric(NinjaMetric.ADVERTISEMENT_VIEWED, {
        ad_unit_id: path,
        ad_slot_id: slotId,
        ad_request_source: slot.requestSource,
      });
      newRelicMetrics.reportMetric(NewRelicMetric.AD_IMPRESSION, { slotId, source: slot.requestSource });
      if (slot.interstitial?.enabled) {
        ninjaMetrics.reportMetric(NinjaMetric.INTERSTITIAL_VIEWABLE, { ad_unit_id: path });
        console.debug('[SLOTS][GOOGLEADS][IMPRESSIONCALLBACK][VIEWABILITY] interstitial_viewable event', path);
      }
    }
  } catch (e) {
    console.error('[SLOTS][GOOGLEADS][IMPRESSIONCALLBACK][ERROR]', e);
    newRelicMetrics.reportError(NewRelicError.GOOGLEADS_IMPRESSION_CALLBACK_ERROR, { message: (e as Error).message });
  }
};

export const setCampaignId = (slot, external) => {
  if (!slot.params.campaignId) slot.params.campaignId = 0;
  if (external.getResponseInformation) {
    const slotData = external.getResponseInformation() || {};
    slot.params.campaignId = slotData.campaignId || 0;
  }
};

export const renderedCallback =
  (external, slot, innerId, containerId, pageId, interstitial, slotId, status, path) => (event) => {
    try {
      console.info('[SLOTS][GOOGLEADS][RENDEREDCALLBACK]', event);
      if (external === event.slot) {
        setCampaignId(slot, external);
        const inner = Html.getElementById(innerId);
        if (inner && inner.style?.display !== 'none') {
          Placeholder.remove(containerId);
        }
        if (inner && interstitial.enabled && !event.isEmpty) {
          ninjaMetrics.reportMetric(NinjaMetric.INTERSTITIAL_IMPRESSION, { ad_unit_id: path });
          console.debug('[SLOTS][GOOGLEADS][RENDEREDCALLBACK] interstitial_impression event', path);
          const { abTest } = slot.params;
          const { INTERSTITIAL_AD_WITH_PRELOADING } = Interstitial.interstitialAbTests;
          if (abTest.includes(INTERSTITIAL_AD_WITH_PRELOADING)) {
            Interstitial?.storeInState?.(slot);
            ninjaMetrics.reportMetric(NinjaMetric.INTERSTITIAL_PRELOADED, { ad_unit_id: slot.path });
            console.debug('[SLOTS][GOOGLEADS][RENDEREDCALLBACK] interstitial_preloaded event', slot.path);
          }
        }
        if (!event.isEmpty) {
          ninjaMetrics.reportMetric(NinjaMetric.ADVERTISEMENT_DISPLAYED, {
            ad_unit_id: slot.path,
            ad_slot_id: slot.id,
            ad_request_source: slot.requestSource,
          });
          slot.impression = false;
          slot.impressionScroll = null;
        }
        window.dispatchEvent(
          buildSlotRenderedEvent(pageId, containerId, slotId, status, slot.requestSource, event.slot, event.isEmpty, id)
        );
      }
    } catch (e) {
      console.error('[SLOTS][GOOGLEADS][RENDEREDCALLBACK]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEADS_RENDERED_CALLBACK_ERROR, { message: (e as Error).message });
    }
  };

export const requestedCallback = (external, interstitial, path) => (event) => {
  try {
    if (external === event.slot) {
      console.info('[SLOTS][GOOGLEADS][REQUESTEDCALLBACK]', event);
      if (interstitial.enabled) {
        ninjaMetrics.reportMetric(NinjaMetric.INTERSTITIAL_ADREQUEST_SENT, { ad_unit_id: path });
        console.debug('[SLOTS][GOOGLEADS][REQUESTEDCALLBACK] interstitial_adrequest_sent event', path);
      }
    }
  } catch (e) {
    console.error('[SLOTS][GOOGLEADS][REQUESTEDCALLBACK]', e);
    newRelicMetrics.reportError(NewRelicError.GOOGLEADS_REQUESTED_CALLBACK_ERROR, { message: (e as Error).message });
  }
};

export const responseReceivedCallback = (external, interstitial, path) => (event) => {
  try {
    if (external === event.slot) {
      console.info('[SLOTS][GOOGLEADS][RESPONSERECEIVEDCALLBACK] ', event);
      if (interstitial.enabled) {
        ninjaMetrics.reportMetric(NinjaMetric.INTERSTITIAL_ADREQUEST_RECEIVED, { ad_unit_id: path });
        console.debug('[SLOTS][GOOGLEADS][RESPONSERECEIVEDCALLBACK] interstitial_adrequest_received event', path);
      }
    }
  } catch (e) {
    console.error('[SLOTS][GOOGLEADS][RESPONSERECEIVEDCALLBACK]', e);
    newRelicMetrics.reportError(NewRelicError.GOOGLEADS_RESPONSE_RECEIVED_CALLBACK_ERROR, {
      message: (e as Error).message,
    });
  }
};
