import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { calculateElapsedTime } from 'BaxterScript/helper/time/TimeElapsedEvaluate';

export const SLOT_RENDERED_EVENT = 'slot_rendered_event';

export async function handleSlotRenderedEvent(slotRenderedEvent) {
  try {
    console.info(`[SLOTS][SLOTRENDEREDEVENTLISTENER][HANDLESLOTRENDEREDEVENT]`, slotRenderedEvent);
    const { pageId, containerId, slotId, status, slot, requestSource, isEmpty, providerId, creationTillRenderedTime } =
      slotRenderedEvent.detail;
    if (containerId) {
      await globalThis.Baxter.context.slotRenderedHandler.handle(
        pageId,
        containerId,
        slotId,
        status,
        requestSource,
        slot,
        isEmpty,
        providerId,
        creationTillRenderedTime
      );
    }
  } catch (e) {
    console.error('[SLOTS][SLOTRENDEREDEVENTLISTENER][HANDLESLOTRENDEREDEVENT]', e);
    newRelicMetrics.reportError(NewRelicError.EVENT_HANDLE_SLOT_RENDERED_ERROR, { message: (e as Error).message });
  }
}

export function buildSlotRenderedEvent(pageId, containerId, slotId, status, requestSource, slot, isEmpty, providerId) {
  console.info(
    `[SLOTS][SLOTRENDEREDEVENTLISTENER][BUILDSLOTRENDEREDEVENT]`,
    pageId,
    containerId,
    slotId,
    status,
    slot,
    isEmpty,
    providerId
  );
  let creationTillRenderedTime;
  if (!status.alreadyRendered) {
    // eslint-disable-next-line no-param-reassign
    status.alreadyRendered = true;
    creationTillRenderedTime = calculateElapsedTime(status.creationDate);
  }
  return new CustomEvent(SLOT_RENDERED_EVENT, {
    detail: {
      pageId,
      containerId,
      slotId,
      status,
      requestSource,
      slot,
      isEmpty,
      providerId,
      creationTillRenderedTime,
    },
  });
}
