/**
 * @deprecated
 * Extend Slot, Lifecycle, etc. entities instead or create a new one.
 * Use globalThis.Baxter.state as storage.
 */
export class SlotContainer {
  private pageId: string;

  private containerId: string;

  private slots: string[];

  private noFillSlots: string[];

  private params: {};

  constructor(pageId: string, containerId: string, slots: string[] = []) {
    this.pageId = pageId;
    this.containerId = containerId;
    this.slots = slots || [];
    this.noFillSlots = [];
    this.params = {};
  }

  match(pageId: string, containerId: string) {
    return this.containerId === containerId && this.pageId === pageId;
  }

  addNoFill(slot: string) {
    this.noFillSlots.push(slot);
  }

  clearNoFill() {
    this.noFillSlots = [];
  }

  didNotFailToFill(slot: string) {
    return this.noFillSlots.indexOf(slot) < 0;
  }
}

/**
 * @deprecated
 * Extend Slot, Lifecycle, etc. entities instead or create a new one.
 * Use globalThis.Baxter.state as storage.
 */
export class SlotContainerRepository {
  private slotContainers: SlotContainer[];

  constructor() {
    this.slotContainers = [];
  }

  saveAll(slotContainers: SlotContainer[]) {
    this.slotContainers.push(...slotContainers);
  }

  find(pageId: string, containerId: string) {
    return (
      this.slotContainers.find((slotContainer) => slotContainer.match(pageId, containerId)) ||
      new SlotContainer(pageId, containerId)
    );
  }

  clearAllNoFills() {
    this.slotContainers.forEach((containerSlot) => {
      containerSlot.clearNoFill();
    });
  }
}
