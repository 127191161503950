import * as Html from 'BaxterScript/helper/browser/Html';
import * as GoogleAds from 'BaxterScript/version/web/provider/googleads/GoogleAds';
import * as GoogleIMA from 'BaxterScript/version/web/provider/googleima/GoogleIMA';
import * as AdSense from 'BaxterScript/version/web/provider/adsense/AdSense';
import * as BaxterNative from 'BaxterScript/version/web/provider/baxter/Baxter';
import * as Facebook from 'BaxterScript/version/web/provider/facebook/Facebook';
import * as AdManager from 'BaxterScript/version/web/provider/admanager/AdManager';
import * as AdManagerStatic from 'BaxterScript/version/web/provider/admanager-static/AdManagerStatic';
import * as State from 'BaxterScript/version/web/core/State';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Provider } from 'BaxterScript/types/Provider';
import { Providers as ProviderIds } from 'BaxterScript/version/web/config/Providers';
import { Slot } from 'BaxterScript/types/Slot';
import { Config } from 'BaxterScript/types/Config';
import { baxterV2Enabled } from 'BaxterScript/version/web/BaxterV2Enabled';

export const webpackExclude = (config: Config): boolean => baxterV2Enabled(config);

export const Providers: Provider[] = [
  GoogleAds,
  AdSense,
  BaxterNative as unknown as Provider,
  Facebook,
  GoogleIMA as unknown as Provider,
  AdManager as unknown as Provider,
  AdManagerStatic as unknown as Provider,
];

export const getById = (providerId: ProviderIds) => Providers.find((provider) => provider.id === providerId);

export const getSettings = (providerId: ProviderIds) => globalThis.Baxter.config?.providers[providerId];

export const init = (providerModule: Provider) => {
  if (providerModule && typeof providerModule.init === 'function') {
    providerModule.init();
  }
};

export const styleDependencies = (providerModule: Provider) => {
  if (providerModule && typeof providerModule.styleDependencies === 'function') {
    const { id: providerId, dependencies: moduleDependencies } = providerModule.styleDependencies();
    moduleDependencies.flat().forEach((dependency) => {
      Html.addStyleToHead(`baxter-style-provider-${providerId}-${dependency.id}`, dependency.url);
    });
  }
};

export const dependencies = (providerModule: Provider, alreadyLoadedDependencies: Record<string, boolean>) => {
  if (providerModule && typeof providerModule.dependencies === 'function') {
    const { id: providerId, dependencies: providerDependencies } = providerModule.dependencies();
    for (const dependency of providerDependencies.flat()) {
      const { id: dependencyId, url } = dependency;
      if (!alreadyLoadedDependencies[url]) {
        Html.addScriptToHead(`baxter-script-provider-${providerId}-${dependencyId}`, url, {
          onload: () => State.setDependencyResolved(url, true),
          onerror: () => State.setDependencyResolved(url, false),
        });
        // eslint-disable-next-line no-param-reassign
        alreadyLoadedDependencies[url] = true;
      }
    }
  }
};

export const loaded = (providerModule: Provider) => {
  if (providerModule && typeof providerModule.loaded === 'function') {
    providerModule.loaded();
  }
};

export const bootstrap = () => {
  const alreadyLoadedDependencies = {};
  Providers.forEach((providerModule) => {
    try {
      init(providerModule);
      styleDependencies(providerModule);
      dependencies(providerModule, alreadyLoadedDependencies);
      loaded(providerModule);
    } catch (e) {
      console.error('[SLOTS][PROVIDER][BOOTSTRAP]', e);
      newRelicMetrics.reportError(NewRelicError.PROVIDER_BOOTSTRAP_ERROR, { message: (e as Error).message });
    }
  });
};

export const consent = async (restrictDataProcessing: boolean) =>
  Promise.all(
    Providers.filter((providerModule) => providerModule?.consent && typeof providerModule.consent === 'function').map(
      async (providerModule) => providerModule.consent?.(restrictDataProcessing)
    )
  );

export const load = async (slots: Record<string, Slot>) =>
  Promise.all(
    Providers.filter((providerModule) => providerModule?.load && typeof providerModule.load === 'function').map(
      async (providerModule) => {
        const providerId = providerModule.id;
        const providerSettings = getSettings(providerId);
        const providerSlots = Object.values(slots).filter((slot) => slot.provider === providerId);
        if (providerSlots.length > 0) {
          if (!(await providerModule.load!(providerSlots))) {
            console.error('[SLOTS][PROVIDER][LOAD]', providerId, providerSlots, providerSettings);
            newRelicMetrics.reportError(NewRelicError.PROVIDER_LOAD_ERROR, { providerId });
          }
        }
      }
    )
  );

export const autoplay = (slot: Slot) =>
  Providers.forEach((providerModule) => {
    if (providerModule && typeof providerModule.autoplay === 'function') {
      if (slot.provider === providerModule.id) {
        providerModule.autoplay(slot);
      }
    }
  });
