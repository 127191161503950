import * as State from 'BaxterScript/version/web/core/State';
import * as Cookie from 'BaxterScript/helper/browser/Cookie';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import * as Provider from 'BaxterScript/version/web/core/Provider';
import { Config } from 'BaxterScript/types/Config';
import { baxterV2Enabled } from 'BaxterScript/version/web/BaxterV2Enabled';

export const webpackExclude = (config: Config): boolean => !config.app?.consent?.enabled || baxterV2Enabled(config);

const consentQueue: { cmd: () => Promise<void>; name: string }[] = [];

const removeLegacyCookie = () => {
  const name = 'OTAdditionalConsentString';
  if (Cookie.get(name)) {
    Cookie.remove(name);
  }
};

const bootstrap = () => {
  removeLegacyCookie();
};

const loadAlreadyInTheQueue = () => consentQueue.find((item) => item.name === 'load');

const processQueue = async () => {
  for (; consentQueue.length; ) {
    const next = consentQueue.shift();
    if (!next) return;

    if (next.name === 'set') {
      console.debug('[SLOTS][CONSENT][PROCESSQUEUE] CALL', next.name, next.cmd);
      // eslint-disable-next-line no-await-in-loop
      await next.cmd.call(undefined);
    }
    if (next.name === 'load') {
      if (!consentQueue.length) {
        console.debug('[SLOTS][CONSENT][PROCESSQUEUE] CALL', next.name, next.cmd);
        // eslint-disable-next-line no-await-in-loop
        await next.cmd.call(undefined);
      } else if (!loadAlreadyInTheQueue()) {
        console.debug('[SLOTS][CONSENT][PROCESSQUEUE] PUSH', next.name, next.cmd);
        consentQueue.push(next);
      }
    }
  }
};

const setUserConsent = async () => {
  try {
    console.info('[SLOTS][CONSENT][SETUSERCONSENT]');
    State.setUserConsentResolved();
    if (globalThis.OptanonActiveGroups || globalThis.OnetrustActiveGroups) {
      if (globalThis.OptanonActiveGroups?.match(/STACK42,/) || globalThis.OnetrustActiveGroups?.match(/C0004,/)) {
        newRelicMetrics.reportMetric(NewRelicMetric.CONSENT_DONT_RESTRICT_DATA_PROCESSING);
        await Provider.consent(false);
      } else {
        newRelicMetrics.reportMetric(NewRelicMetric.CONSENT_RESTRICT_DATA_PROCESSING);
        await Provider.consent(true);
      }
      await processQueue();
    } else {
      console.debug('[SLOTS][CONSENT][SETUSERCONSENT] missing active groups');
      newRelicMetrics.reportMetric(NewRelicMetric.CONSENT_MISSING_ACTIVE_GROUPS);
      await Provider.consent(true);
      await processQueue();
    }
  } catch (error) {
    console.error('[SLOTS][CONSENT][SETUSERCONSENT]', error);
    newRelicMetrics.reportError(NewRelicError.CONSENT_SET_USER_CONSENT, { message: (error as Error).message });
  }
};

const isPending = () => !State.isUserConsentResolved();

const addToQueue = (name, cmd) => {
  if (typeof cmd === 'function') {
    console.debug('[SLOTS][CONSENT][ADDTOQUEUE] PUSH', name, cmd);
    consentQueue.push({ name, cmd });
  }
};

const delaySet = (cmd) => {
  if (isPending()) {
    addToQueue('set', cmd);
    return true;
  }
  return false;
};

const emptyQueue = () => consentQueue.length === 0;

const delayLoad = (cmd) => {
  if (isPending() && !loadAlreadyInTheQueue()) {
    addToQueue('load', cmd);
    return true;
  }
  if (!emptyQueue() && !loadAlreadyInTheQueue()) {
    addToQueue('load', cmd);
    return true;
  }
  return false;
};

export default {
  bootstrap,
  setUserConsent,
  isPending,
  delaySet,
  delayLoad,
};
